import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Observable } from 'rxjs/Rx';


@Injectable()
export class AuthGuard implements CanActivate {
  env = localStorage.getItem('currentEnv') || null;
  constructor(public router: Router, private auth: AuthenticationService) {

  }

  async canActivate(): Promise<boolean> {
    await this.checkLoginStatus();
    return this.checkUserStatus();
  }

  async checkLoginStatus(): Promise<any> {
    return await this.auth.getToken().then(
    (resp) => {
      const token = resp;
      return this.checkAuthStatus(token);
    }
    ).catch((error: any) => {
      this.logout();
      console.log(error);
      return false;
    });
  }

  checkAuthStatus(token: any): Observable<boolean> {
    localStorage.setItem('csrfToken', token);
    return this.auth.getCurrentUser().map(
      response => {
        const res = response.json();
        if (res.user.account.uid === '0') {
          this.logout();
          return false;
        }
        this.auth.saveUserInfo(res);
        return true;
      },
      (error: any) => {
        console.log(error);
        this.logout();
        return false;
      });
  }

  checkUserStatus(): boolean {
    const token = localStorage.getItem('csrfToken');
    const check = this.auth.checkLoggedIn(token);
    if (!check) {
      this.router.navigate(['/']);
    }
    return check;
  }

  logout(): void {
    localStorage.removeItem('csrfToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentEnv');
    this.router.navigate(['/']);
  }
}
