import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CdxmlService, UserPrivilegeService, UtilityService } from '../../services/index';
import { AngularFireList } from '@angular/fire/database';
import { viewAllHistory } from '../../models/userPrivilege.config';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.css'],
  providers: [ CdxmlService, UserPrivilegeService, UtilityService ]
})
export class ImportHistoryComponent implements OnChanges, OnInit {
  currentUser: any;
  currentUserId: string;
  history: Array<any>;
  importResponseObservable: AngularFireList<any[]>;
  validationResponse: Array<any>;
  importHistoryDataSource: MatTableDataSource<any>;
  historyDisplayedColumns: string[] = ['created', 'contentType', 'fileType', 'importProcess', 'sellerId', 'targetUrl', 'actions'];
  allUserHistoryDataSource: MatTableDataSource<any>;
  allUserIds: Array<any>;
  progressSpinner = true;
  errorHistory = false;
  emptyHistory = false;
  emptySpecificUserHistory = false;
  rowsOnPage = 10;
  validationSpinner = false;
  noValidationResponse = false;
  errorValidationResponse = false;
  userId: string;
  requestId: string;
  convertedResponseId: string;
  allHistoryPrivilege = false;
  showHistory = 'myHistory';
  showResponseTable = false;
  specificUserId: number;
  showMyHistory = true;
  offsets = [
    {value: 5, viewValue: '5'},
    {value: 10, viewValue: '10'},
    {value: 25, viewValue: '25'},
    {value: 100, viewValue: '100'}
  ];
  offsetMyHistory = 5;
  offsetAllHistory = 5;
  nextKeyMyHistory: any;
  prevKeysMyHistory: any[] = [];
  requestsMyHistory: any;
  nextKeyAllHistory: any;
  prevKeysAllHistory: any[] = [];
  requestsAllHistory: any;
  @Input() onGoingImportDataFromImport?: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private cdxml: CdxmlService, private ups: UserPrivilegeService, private utils: UtilityService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.setUserId(this.currentUser.account.uid);
    }
  }

  ngOnInit() {
    this.checkAdminPrivilege();
    this.getAllUserIds();
    this.getImportHistory();
  }

  ngOnChanges() {
    //noinspection TsLint
    if (this.onGoingImportDataFromImport) {
      this.userId = this.onGoingImportDataFromImport.userId;
      this.requestId = this.onGoingImportDataFromImport.requestId;
      this.convertedResponseId = this.onGoingImportDataFromImport.convertedResponseId;
      this.showResponseTable = true;
    }
  }

  isSelectedRow(requestId: string): boolean {
    return this.requestId === requestId;
  }

  isActiveUserId(userId: number): boolean {
    return this.specificUserId === userId;
  }

  setUserId(userId: string): void {
    this.currentUserId = userId;
  }

  checkAdminPrivilege(): void {
    this.allHistoryPrivilege = this.ups.userPrivilege(viewAllHistory);
  }

  historyOptionChanged(event: any): void {
    this.checkHistoryOptionChoosen(event).snapshotChanges().subscribe(data => {
      const convertedArray = data.map(r => ({$key: r.key, ...r.payload.val()}));
      if (event.value === 'allHistory') {
        this.populateAllHistory(convertedArray);
      }
      else {
        this.showHistoryData(convertedArray);
      }
      this.progressSpinner = false;
    },
      (error) => {
        this.progressSpinner = false;
        this.errorHistory = true;
        console.log(error);
      }
    );
  }

  getAllUserIds(): void {
    const ids = [];
    const fbRef = this.cdxml.getResponseAsList('AccessControl/');
    const allUserIdsSub = fbRef.snapshotChanges().subscribe(data => {
      const userData = data.map(r => ({$key: r.key, ...r.payload.val()}));
      if (userData.length > 0) {
        userData.forEach(element => {
          ids.push(element['$key']);
        });
        this.allUserIds = ids;
      }
    },
    (error) => {
      console.log(error);
    },
    () =>  {
      allUserIdsSub.unsubscribe();
    });
  }

  checkHistoryOptionChoosen(event: any, key?: string): any {
    if (event.value === 'allHistory' && this.allHistoryPrivilege) {
      return this.cdxml.getResponseAsList('Requests/');
    }
    else {
      return this.cdxml.getDataForPaginationSortedByCreated('Requests/' + this.currentUserId, this.offsetMyHistory, key);
    }
  }

  populateAllHistory(data: Array<any>): void {
    this.allUserHistoryDataSource  = new MatTableDataSource([]);
    if (this.showHistory === 'allHistory' && data.length > 0 && this.allHistoryPrivilege) {
      this.emptyHistory = false;
      const userID = this.allUserIds[0];
      this.specificUserId = userID;
      const firstData = this.cdxml.getDataForPaginationSortedByCreated('Requests/' + userID, this.offsetAllHistory);
      const subscription = firstData.snapshotChanges().subscribe(res => {
        const convertToArray = res.map(r => ({$key: r.key, ...r.payload.val()}));
        if (convertToArray.length > 0) {
          if (convertToArray.length >= this.offsetAllHistory + 1) {
            this.requestsAllHistory = _.slice(convertToArray, 1, this.offsetAllHistory + 1);
            this.nextKeyAllHistory = _.get(convertToArray[0], 'created');
          }
          else {
            this.requestsAllHistory = convertToArray;
            this.nextKeyAllHistory = null;
          }
        this.allUserHistoryDataSource = new MatTableDataSource(this.requestsAllHistory.reverse());
        this.allUserHistoryDataSource.paginator = this.paginator;
        this.allUserHistoryDataSource.sort = this.sort;
        subscription.unsubscribe();
        }
        else {
          this.emptySpecificUserHistory = true;
        }
      },
      (error) => {
        console.log(error);
      }
      );
    }
    else {
      this.emptyHistory = false;
      this.emptySpecificUserHistory = true;
    }
  }

  nextPageMyHistory(): void {
    this.prevKeysMyHistory.push(_.first(this.requestsMyHistory)['created']);
    this.getImportHistory(this.nextKeyMyHistory);
  }

  prevPageMyHistory(): void {
    const prevKey = _.last(this.prevKeysMyHistory);
    this.prevKeysMyHistory = _.dropRight(this.prevKeysMyHistory);
    this.getImportHistory(prevKey);
  }

  noOfItemsOffsetsChangedMyHistory(): void {
    this.getImportHistory();
  }

  getImportHistory(key?: string): void {
    const history = this.cdxml.getDataForPaginationSortedByCreated('Requests/' + this.currentUserId, this.offsetMyHistory, key);
    const subscription = history.snapshotChanges().subscribe(data => {
      const importHistory = data.map(r => ({$key: r.key, ...r.payload.val()}));
      if (data) {
        this.showHistoryData(importHistory);
      }
      else {
        this.emptyHistory = true;
        this.progressSpinner = false;
      }
    },
    (error) => {
      this.progressSpinner = false;
      this.errorHistory = true;
      console.log(error);
    },
    () => {
      subscription.unsubscribe();
    });
  }

  showHistoryData(data: Array<any>): void {
    this.importHistoryDataSource = new MatTableDataSource([]);
    if (data.length > 0) {
      if (data.length >= this.offsetMyHistory + 1) {
        this.requestsMyHistory = _.slice(data, 1, this.offsetMyHistory + 1);
        this.nextKeyMyHistory = _.get(data[0], 'created');
      }
      else {
        this.requestsMyHistory = data;
        this.nextKeyMyHistory = null;
      }
      this.showMyHistory = true;
      this.emptyHistory = false;
      this.importHistoryDataSource =  new MatTableDataSource(this.requestsMyHistory.reverse());
      this.importHistoryDataSource.paginator = this.paginator;
      this.importHistoryDataSource.sort = this.sort;
    }
    else {
      this.showMyHistory = false;
      this.emptyHistory = true;
    }
    this.progressSpinner = false;
  }

  nextPageAllHistory(userId: number): void {
    this.prevKeysAllHistory.push(_.first(this.requestsAllHistory)['created']);
    this.showSpecificUserHistory(userId, this.nextKeyAllHistory);
  }

  prevPageAllHistory(userId: number): void {
    const prevKey = _.last(this.prevKeysAllHistory);
    this.prevKeysAllHistory = _.dropRight(this.prevKeysAllHistory);
    this.showSpecificUserHistory(userId, prevKey);
  }

  noOfItemsOffsetsChangedAllHistory(userId: number): void {
    this.showSpecificUserHistory(userId);
  }

  showSpecificUserHistory(userId: number, key?: string): void {
    this.allUserHistoryDataSource  = new MatTableDataSource([]);
    this.specificUserId = userId;
    const history = this.cdxml.getDataForPaginationSortedByCreated('Requests/' + userId, this.offsetAllHistory, key);
    const subscription = history.snapshotChanges().subscribe(data => {
      if (data.length > 0) {
        const importHistory = data.map(r => ({$key: r.key, ...r.payload.val()}));
        if (data.length >= this.offsetMyHistory + 1) {
          this.requestsAllHistory = _.slice(importHistory, 1, this.offsetAllHistory + 1);
          this.nextKeyAllHistory = _.get(importHistory[0], 'created');
        }
        else {
          this.requestsAllHistory = importHistory;
          this.nextKeyAllHistory = null;
        }
        this.allUserHistoryDataSource = new MatTableDataSource(this.requestsAllHistory.reverse());
        this.allUserHistoryDataSource.paginator = this.paginator;
        this.allUserHistoryDataSource.sort = this.sort;
        subscription.unsubscribe();
        this.emptySpecificUserHistory = false;
      }
      else {
        this.emptySpecificUserHistory = true;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }

  showHistoryDetails(userId: string, requestId: string): void {
    this.showLogDetails(userId, requestId);
    if (userId && requestId) {
      this.validationSpinner = true;
      const conversionResponse = this.cdxml.getResponseAsObject('ConvertedData/' + userId + '/' + requestId);
      const conversionSubscription = conversionResponse.valueChanges().subscribe((response) => {
        if (response) {
          const convertToArray = this.utils.convertObjectToArray(response);
          if (convertToArray.length > 0) {
            conversionSubscription.unsubscribe();
            this.convertedResponseId = convertToArray[0].key;
            if (this.convertedResponseId !== null) {
              this.importResponseObservable = this.cdxml.getResponseAsList('ImportResponse/' + userId + '/' + requestId + '/' + this.convertedResponseId);
              const importSubscription = this.importResponseObservable.valueChanges().subscribe(res => {
                  this.checkForHistoryDetails(res);
                  importSubscription.unsubscribe();
                },
                (error: any) => {
                  console.log(error);
                  this.validationSpinner = false;
                  this.errorValidationResponse = true;
                });
            }
          }
          else {
            this.showNoValidationResponse();
          }
        }
        else {
          this.showNoValidationResponse();
        }
      });
    }
    else {
      this.showNoValidationResponse();
    }
  }

  checkForHistoryDetails(response: Array<any>): void {
    if (response.length > 0) {
      this.showResponseTable = true;
      this.validationSpinner = false;
      this.validationResponse = this.utils.sort(response, 'created', UtilityService.OPTION_SORT_ASC, 'date');
      this.emptyHistory = false;
      this.errorHistory = false;
      this.noValidationResponse = false;
      this.errorValidationResponse = false;
    }
    else {
      this.showNoValidationResponse();
    }
  }

  showNoValidationResponse(): void {
    this.showResponseTable = false;
    this.validationSpinner = false;
    this.noValidationResponse = true;
  }

  showLogDetails(user: string, reqId: string): void {
    this.requestId = reqId;
    this.userId = user;
  }
}
