import { Injectable } from '@angular/core';
import { mustHaveRoles } from '../../models/userPrivilege.config';

@Injectable()
export class UserPrivilegeService {
  constructor() {
  }

  userPrivilege(userRoles: Array<number>): boolean {
    const privileges = this.userData();
    if (privileges) {
      const roles = privileges.account.roles;
      return this.checkRoles(roles, mustHaveRoles, userRoles);
    }
    return false;
  }

  userData(): any {
    if (localStorage.getItem('currentUser')) {
      return (JSON.parse(localStorage.getItem('currentUser')));
    }
    return null;
  }

  checkRoles(roles: any, checkAllRoles: Array<any>, checkAnyRole: Array<any>): boolean {
    for (const id of checkAllRoles) {
      if (!roles.hasOwnProperty(id)) {
        return false;
      }
    }
    return this.checkAnyRole(roles, checkAnyRole);
  }

  checkAnyRole(roles: any, checkAnyRole: Array<any>): boolean {
    if (checkAnyRole.length > 0) {
      for (const id of checkAnyRole) {
        if (roles.hasOwnProperty(id)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }
}
