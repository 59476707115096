import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class CdApiService {
  private headers: Headers;
  private body: string|Object;
  public baseUrl: string;
  public lang: string;

  constructor(private http: Http) {
    this.body = {};
    this.headers = new Headers({});
  }

  callHttpPost(url: string): Observable<any> {
    const options = new RequestOptions({ headers: this.headers, withCredentials: true});
    return this.http.post(this.baseUrl + this.lang + url, this.body, options);
  }

  callHttpGet(url: string): Observable<any> {
    const options = new RequestOptions({ headers: this.headers, withCredentials: true});
    if (typeof this.lang === 'undefined') {
      return this.http.get(this.baseUrl + url, options);
    }
    return this.http.get(this.baseUrl + this.lang + url, options);
  }

  setFormEncodedContentType(): void {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
  }

  setToken(token: string): void {
    this.headers.set('X-CSRF-Token', token);
  }

  setBody(body: string|Object): void {
    this.body = body;
  }

  setHeaderValue(key: string, value: string): void {
    this.headers.set(key, value);
  }

  getHeaderValue(key: string): string {
    return this.headers.get(key);
  }
}
