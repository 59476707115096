export interface UserLogin {
  email: string;
  password: string;
}

// not used right now but can be used to control access of user actions like read, write
export interface Roles {
  authenticatedUser: boolean; // 2
  superAdmin?: boolean; // 3
  dealer?:  boolean; // 4
  auctionHouse?: boolean; // 7
  admin?: boolean; // 11
  cdMember?: boolean; // 16
  sales?: boolean; // 17
  developer?: boolean; // 23
  techSupport?: boolean; // 25
}
// -------

export class User {
  roles: Roles;
  email: string;

  constructor(authData: any) {
    this.email = authData.email;
    this.roles = authData.roles;
  }
}
