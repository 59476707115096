import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-env',
  templateUrl: './current-env.component.html',
  styleUrls: ['./current-env.component.css']
})

export class CurrentEnvComponent {
  currentEnv: string;
  constructor(public router: Router) {
    router.events.subscribe(() => {
      this.resetComponentState();
    });
  }

  resetComponentState() {
    this.currentEnv = localStorage.getItem('currentEnv') || null;
  }
}
