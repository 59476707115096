import { Component, Output, EventEmitter } from '@angular/core';
import { UploadService } from '../../services';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
  providers: [UploadService]
})

export class UploadFileComponent {
  currentUser: any;
  userId: string;
  percentUploaded = 0;
  bufferValue = 75;
  uploadUrl: string;
  showProgressBar = false ;
  fileName: string = null;
  modifiedFileName: string = null;
  fileDescription: string = null;
  showHistory = false;
  fileEvent: any;
  historyLabel = 'View History';
  disableSubmit = true;
  @Output() copyButtonClicked = new EventEmitter<string>();

  constructor(private uploadService: UploadService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.setUserId(this.currentUser.account.uid);
    }
    this.uploadUrl = null;
  }

  setUserId(userId: string): void {
    this.userId = userId;
  }

  getFile($event: any): void {
    this.fileEvent = $event;
    this.disableSubmit = false;
    this.fileDescription = null;
    this.uploadUrl = null;
    this.showProgressBar = false;
  }

  uploadFile(): void {
    this.showProgressBar = true;
    this.disableSubmit = true;
    this.fileName = this.fileEvent.target.files[0].name;
    const uploadTask = this.uploadService.uploadFile(this.fileEvent.target.files[0], this.userId);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        this.percentUploaded = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.uploadUrl = null;
        uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
          this.uploadUrl = downloadUrl;
          this.passCopyLinkToHome(this.uploadUrl);
          if (!this.fileDescription) {
            this.fileDescription = uploadTask.snapshot.metadata.name;
          }
          this.modifiedFileName = uploadTask.snapshot.metadata.name;
          this.uploadService.saveFileInfo(this.uploadUrl, this.fileName, this.modifiedFileName, this.fileDescription, this.userId);
          this.fileEvent.target.value = '';
          this.fileDescription = null;
        });

      }
    );
  }

  showFileHistory(): void {
    this.showHistory = !this.showHistory;
    if (this.showHistory) {
      this.historyLabel = 'Hide History';
    }
    else {
      this.historyLabel = 'View History';
    }
  }

  submitForm(event: any) {
    if (event.keyCode === 13) {
      if (this.fileEvent) {
        this.uploadFile();
      }
    }
  }

  passCopyLinkToHome(url: string): void {
    this.copyButtonClicked.emit(url);
  }
}
