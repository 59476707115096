import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CdApiService, CdxmlService, UtilityService } from '../../services';
import {Observable} from 'rxjs';
import * as config from '../../config/requestStatus.config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css'],
  providers: [CdApiService, CdxmlService, UtilityService]
})
export class ToolsComponent implements OnInit {
  env: string;
  @Output() showToolsMenuStatus = new EventEmitter<string>();
  @Output() showHideProgressBar = new EventEmitter<any>();

  constructor(private cdapi: CdApiService, private cdxml: CdxmlService, private utils: UtilityService, private router: Router) {
    this.setBaseUrl();
  }

  ngOnInit() {
  }

  setBaseUrl(): void {
    this.cdxml.getResponseAsObject('Config/environments').valueChanges().subscribe(env => {
      const convertToArray = this.utils.convertObjectToArray(env);
      if (typeof convertToArray[0].key !== 'undefined') {
        if (convertToArray[0].key === 'dev' || convertToArray[0].key === 'test') {
          this.cdapi.baseUrl = 'https://import-' + convertToArray[0].key + '.classicdriver.com/';
        }
        else {
          this.cdapi.baseUrl = 'https://import.classicdriver.com/';
        }
      }
    },
    (error) => {
      console.log(error);
      this.showToolsMenuStatus.emit('Sorry we could not set the environment for the request');
    });
  }

  refreshAuth(): void {
    this.showHideProgressAndSnackbar('Refreshing Auth Cookie', config.RequestStatuses.Requested);
    this.doHttpCall('refreshApiAuth').subscribe(apiResponse => {
      if (apiResponse.json()) {
        this.showHideProgressAndSnackbar('Refreshing Auth Complete', config.RequestStatuses.Completed);
      }
    },
    (error) => {
      console.log(error);
      this.showHideProgressAndSnackbar('Error refreshing auth. Please try again', config.RequestStatuses.Error);
    });
  }

  refreshImportQueue(): void {
    this.showHideProgressAndSnackbar('Refreshing Import Queue', config.RequestStatuses.Requested);
    this.doHttpCall('refreshImportQueue').subscribe(apiResponse => {
      const msg = this.getApiResponseMessage(apiResponse);
      if (msg !== null && typeof msg !== 'undefined' && typeof msg === 'string') {
        this.showHideProgressAndSnackbar(msg, config.RequestStatuses.Completed);
      }
      else {
        this.showUnknownErrorExist();
      }
    },
    (error) => {
      const msg = this.getApiResponseMessage(error);
      if (msg !== null && typeof msg !== 'undefined' && typeof msg === 'string') {
        this.showHideProgressAndSnackbar(msg, config.RequestStatuses.Error);
      }
      else {
        console.log(error);
        this.showUnknownErrorExist();
      }
    });
  }

  showUnknownErrorExist(): void {
    this.showHideProgressAndSnackbar('Something went wrong while refreshing import queue', config.RequestStatuses.Error);
  }

  getApiResponseMessage(apiResponse: any): string {
    if (apiResponse && apiResponse._body && typeof apiResponse._body === 'string') {
      const parseResponse = JSON.parse(apiResponse._body);
      if (parseResponse !== null && typeof parseResponse !== 'undefined' && parseResponse.msg) {
        return parseResponse.msg;
      }
    }
    return null;
  }

  doHttpCall(url: string): Observable<any> {
    return this.cdapi.callHttpGet(url);
  }

  showHideProgressAndSnackbar(message: string, status: any) {
    this.showToolsMenuStatus.emit(message);
    this.showHideProgressBar.emit(status);
  }

  viewImportQueue(): void {
    this.router.navigate(['/import-queue']);
  }
}
