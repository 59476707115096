import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayobjectlength'
})
export class ArrayObjectLength implements PipeTransform {

  transform(value: any): number {
    if (value.isArray) {
      return value.length;
    }
    else if (value !== null && typeof value === 'object') {
      return Object.keys(value).length;
    }
    return null;
  }
}
