import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { CdxmlService } from '../../services/index';
import { MatSort, MatTableDataSource } from '@angular/material';
import { AngularFireList } from '@angular/fire/database';
import * as _ from 'lodash';

@Component({
  selector: 'app-log-table',
  templateUrl: './log-table.component.html',
  styleUrls: ['./log-table.component.css'],
  providers: [CdxmlService]
})
export class LogTableComponent implements OnChanges {
  @Input() userId: string;
  @Input() requestId: string;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['data', 'severity', 'timestamp'];
  errorLogHistory = false;
  emptyLogHistory = false;
  offsets = [
    {value: 5, viewValue: '5'},
    {value: 10, viewValue: '10'},
    {value: 25, viewValue: '25'},
    {value: 100, viewValue: '100'}
  ];
  offset = 5;
  nextKey: any;
  prevKeys: any[] = [];
  listings: any;

  constructor(private cdxml: CdxmlService) {
    this.getLogHistory();
  }

  ngOnChanges() {
    this.getLogHistory();
  }

  nextPage() {
    this.prevKeys.push(_.first(this.listings)['$key']);
    this.getLogHistory(this.nextKey);
  }

  prevPage() {
    const prevKey = _.last(this.prevKeys);
    this.prevKeys = _.dropRight(this.prevKeys);
    this.getLogHistory(prevKey);
  }

  getLogHistory(key?: string): void {
    this.dataSource = new MatTableDataSource([]);
    this.errorLogHistory = false;
    this.emptyLogHistory = false;
    if (this.userId && this.requestId) {
      const fbRef = 'Logs/' + this.userId + '/' + this.requestId;
      const history: AngularFireList<any> = this.cdxml.getDataForPagination(fbRef, this.offset, key);
      const logHistorySubscription = history.snapshotChanges().subscribe(data => {
        const logHistory = data.map(r => ({$key: r.key, ...r.payload.val()}));
        if (logHistory.length > 0) {
          logHistorySubscription.unsubscribe();
          this.listings = _.slice(logHistory, 0, this.offset);
          this.nextKey = _.get(logHistory[this.offset], '$key');
          this.dataSource = new MatTableDataSource(this.listings);
          this.dataSource.sort = this.sort;
        }
        else {
          this.emptyLogHistory = true;
        }
      },
      (error) => {
        this.errorLogHistory = true;
        console.log(error);
      },
      () => {
        logHistorySubscription.unsubscribe();
      });
    }
  }

  noOfItemsOffsetsChanged() {
    this.getLogHistory();
  }
}
