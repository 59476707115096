import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CdxmlService } from '../../services/index';
import { MatSnackBar } from '@angular/material';
import * as config from '../../config/requestStatus.config';

@Component({
  selector: 'app-import-queue',
  templateUrl: './import-queue.component.html',
  styleUrls: ['./import-queue.component.css'],
  providers: [CdxmlService]
})
export class ImportQueueComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  userId: string;
  emptyQueue = false;
  loading = false;
  errorRetrevingData = false;
  requestIDs: Array<string>;
  selectedRequestID: string;
  importQueueDataSource: MatTableDataSource<any>;
  showProgressBar = false;
  tableHeaders = ['attempts', 'created', 'lastUpdated', 'status'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private cdxml: CdxmlService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.showImportQueue();
  }

  gotoHome(): void {
    this.router.navigate(['/home']);
  }

  showImportQueue(): void {
    this.userId =  this.currentUser.account.uid;
    this.importQueueDataSource = new MatTableDataSource([]);
    const importQueue = this.cdxml.getLatestDataAsList('ImportQueue/' + this.userId);
    importQueue.valueChanges().subscribe(res => {
      if (res.length > 0 && res !== null && typeof res !== 'undefined') {
        let data = res[0];
        data = this.checkIfDataIsArray(data);
        data = data.filter(val => val);
        data = this.checkIfDataIsArray(data[0]);
        this.importQueueDataSource = new MatTableDataSource(data);
        this.importQueueDataSource.paginator = this.paginator;
        this.importQueueDataSource.sort = this.sort;
        }
        else {
          this.emptyQueue = true;
        }
      this.loading = false;
    },
    (error) => {
      console.log(error);
      this.errorRetrevingData = true;
      this.loading = false;
    });
  }

  checkIfDataIsArray(data: any) {
    if (!Array.isArray(data)) {
      return Object.values(data);
    }
    return data;
  }

  showImportValidateStatusMsg(msg: string): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
    });
  }

  showHideProgressBar(status: any): void {
    if (status === config.RequestStatuses.Requested) {
      this.setProgressBarVisibility();
    }
    else {
      this.unsetProgressBarVisibility();
    }
  }

  setProgressBarVisibility(): void {
    this.showProgressBar = true;
  }

  unsetProgressBarVisibility(): void {
    this.showProgressBar = false;
  }
}
