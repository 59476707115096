import { Component, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { CdxmlService } from '../../services';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AngularFireList } from '@angular/fire/database';

@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html',
  styleUrls: ['./file-history.component.css'],
  providers: [CdxmlService]
})

export class FileHistoryComponent implements OnInit {
  currentUser: any;
  userId: string;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fileName', 'created', 'fileDescription', 'src', 'actions'];
  emptyHistory = false;
  errorHistory = false;
  progress = true;

  @Output() copyButtonClicked = new EventEmitter<string>(); // creating an output event
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private cdxml: CdxmlService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.setUserId(this.currentUser.account.uid);
    }
  }

  ngOnInit() {
    this.getUserFileHistory();
  }

  setUserId(userId: string): void {
    this.userId = userId;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getUserFileHistory() {
    const dataSourceList = [];
    this.dataSource = new MatTableDataSource([]);
    const history: AngularFireList<any> = this.cdxml.getResponseAsList('FileUploads/' + this.userId + '/files');
    history.snapshotChanges().subscribe(data => {
      data.forEach(element => {
        dataSourceList.push(element.payload.toJSON());
      });
      if (dataSourceList.length > 0) {
        this.dataSource =  new MatTableDataSource(dataSourceList.reverse());
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else {
        this.emptyHistory = true;
      }
    },
      (error) => {
        this.errorHistory = true;
        console.log(error);
      }
    );
    this.progress = false;
  }

  copyUrlToImportValidate(url: string): void {
    this.copyButtonClicked.emit(url);
  }
}
