import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})

export class NavbarComponent implements OnInit {
  showMessage = false;
  constructor(private router: Router) {
    this.showWelcomeMessage();
  }

  ngOnInit() {
  }

  showWelcomeMessage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/home') {
          this.showMessage = true;
        }
        else {
          this.showMessage = false;
        }
      }
    });
  }
}
