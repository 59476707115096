import { UserLogin } from './../../models/user-login.model';
import { ConfigService } from './../../services/config/config.service';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  providers: [AuthenticationService, ConfigService]
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  errorMessage: any;
  showProgress = false;
  environments = <any>{};
  currentEnv = localStorage.getItem('currentEnv') || null;
  envUrl = localStorage.getItem('envUrl') || null;
  displayEnvironments = false;
  constructor(
  public auth: AuthenticationService,
  public router: Router,
  private fb: FormBuilder,
  private config: ConfigService,
  public snackBar: MatSnackBar) {
    this.auth.env = this.currentEnv;
    this.config.environments.valueChanges().subscribe(envs => {
      this.environments = envs;
      const envKeys = Object.keys(this.environments);
      this.displayEnvironments = envKeys.length > 1;
      if (envKeys.length === 1) {
        this.currentEnv = envKeys[0];
        this.envUrl = this.environments[this.currentEnv];
        localStorage.setItem('currentEnv', this.currentEnv);
        localStorage.setItem('envUrl', this.envUrl);
      }
      this.getToken();
    });
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
  this.signinForm = this.fb.group({
      'username': ['', [
        Validators.required,
        Validators.email]
        ],
      'password': ['', [
        Validators.required]
        ],
      'targetEnv': ['',
        this.envValidation()
        ]
    });
  }

  envValidation(): Array<ValidationErrors> {
    if (this.displayEnvironments) {
      return [Validators.required];
    }
    return null;
  }

  onSubmit(): void {
    const user: UserLogin = {
      email: this.signinForm.value.username,
      password: this.signinForm.value.password
    };
    this.envUrl = this.environments[this.currentEnv];
    this.login(user);
  }

  async login(user: UserLogin): Promise<any> {
    this.auth.getToken().then(
      token => {
        localStorage.setItem('csrfToken', token);
        localStorage.setItem('currentEnv', this.currentEnv);
        localStorage.setItem('envUrl', this.environments[this.currentEnv]);
        this.doLogin(user);
      },
      error => {
        this.errorMessage = error.json();
      }
    );
  }

  doLogin(user: UserLogin): void {
    this.showProgress = true;
    this.auth.authenticate(user).subscribe(
      response => {
        const res = response.json();
        this.auth.authenticateFirebase(res.jwt).then((firebase: any) => {
          if (Number(firebase.user.uid) > 0) {
            const saveData = this.auth.saveUserInfo(res);
            if (saveData) {
              const currentUser = JSON.parse(localStorage.getItem('currentUser'));
              localStorage.setItem('csrfToken', currentUser.token);
              this.showProgress = false;
              this.router.navigate(['/home']);
            }
          }
          else {
            this.auth.logoutIfLoggedIn();
          }
        });

      },
      error => {
        this.showProgress = false;
        console.log(error);
        this.errorMessage = error.json();
        this.getToken();
      }
    );
  }

  getToken(): void {
    this.snackBar.open('Checking if you are logged in', 'X', {
      duration: 5000,
    });
    this.auth.getToken().then(
      token => {
        localStorage.setItem('csrfToken', token);
        this.getCurrentUser();
      },
      error => {
        this.errorMessage = error.json();
      }
    );
  }

  getCurrentUser(): void {
    this.auth.getCurrentUser().subscribe(
      response => {
        const res = response.json();
        this.checkForConnection(res);
      },
      error => {
        console.log(error);
      }
    );
  }

  checkForConnection(response: any): void {
    const token = localStorage.getItem('csrfToken');
    response['token'] = token;
    response['loggedIn'] = true;
    if (response.user.account.uid !== '0') {
      if (this.auth.authenticated) {
        const saveData = this.auth.saveUserInfo(response);
        if (saveData) {
          this.router.navigate(['/home']);
        }
      }
      else {
        this.auth.logout();
      }
    }
    else {
      this.auth.logoutIfLoggedIn();
      this.snackBar.open('You are not logged in. Please login', 'X', {
      duration: 3000,
    });
    }
  }
}
