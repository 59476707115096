export const mustHaveRoles = [2];
// 2:authenticated user
export const  normalUser = [4, 7, 11, 3, 23, 17, 25];
// 4: Dealer, 7:Auction House, 11: "Admin", 3: "Super Admin", 23: "Developers" 17: "Sales" 25: "Tech Support"
export const  viewSellerId = [11, 3, 23, 17, 25];
// 11: "Admin", 3: "Super Admin", 23: "Developers" 17: "Sales" 25: "Tech Support"
export const  viewAllHistory = [3, 23, 17, 25];
// 3: "Super Admin", 23: "Developers" 17: "Sales" 25: "Tech Support"
export const  viewLogs = [3, 23, 17, 25];
// 3: "Super Admin", 23: "Developers" 17: "Sales" 25: "Tech Support"
