import { CdxmlRequest } from '../../models/cdxml-request.model';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

@Injectable()
export class CdxmlService {
  currentUser: any;
  userId: string;
  fbRef: AngularFireList<any>;
  constructor(private af: AngularFireDatabase) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.setUserPath(this.currentUser.account.uid);
    }
  }

  setUserPath(userId: string): void {
    this.userId = userId;
    this.fbRef = this.af.list('Requests/' + this.userId);
  }

  addToFirebase(formInputs: CdxmlRequest): string {
    const request = formInputs;
    request.served = false;
    request.created = firebase.database.ServerValue.TIMESTAMP;
    const requestKey = this.fbRef.push(request).key;
    return requestKey;
  }

  getResponseAsList(reference: string): AngularFireList<any> {
    return this.af.list(reference);
  }

  getResponseAsObject(reference: string): AngularFireObject<any> {
    return this.af.object(reference);
  }

  getLatestDataAsList(reference: string): AngularFireList<any> {
    return this.af.list(reference, ref => ref.orderByKey().limitToLast(1));
  }

  getDataForPagination(reference: string, offset: number, startKey?: string): AngularFireList<any> {
    if (typeof startKey  !== 'undefined') {
      return this.af.list(reference, ref => ref.orderByKey().startAt(startKey).limitToFirst(offset + 1));
    }
    else {
      return this.af.list(reference, ref => ref.orderByKey().limitToFirst(offset + 1));
    }
  }

  getDataForPaginationSortedByCreated(reference: string, offset: number, startKey?: string): any {
    if (typeof startKey  !== 'undefined') {
      return this.af.list(reference, ref => ref.orderByChild('created').endAt(startKey, 'created').limitToLast(offset + 1));
    }
    else {
      return this.af.list(reference, ref => ref.orderByChild('created').limitToLast(offset + 1));
    }
  }
}
