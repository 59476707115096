import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable()
export class UploadService {
  fbRef: AngularFireList<any>;
  private basePath = '/fileUploads';
  match = false;

  constructor(private af: AngularFireDatabase) {
  }

  uploadFile(filePayload: any, userId: string): firebase.storage.UploadTask {
    this.basePath = 'fileUploads/' + userId;
    const fileName = this.getFileName(filePayload.name);
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${this.basePath}/${fileName}`).put(filePayload);
  }

  getFileName(fileName: string) {
    const fileExtension = fileName.split('.').pop();
    const extractedFileName = fileName.substr(0, fileName.lastIndexOf('.'));
    return extractedFileName + '-' + new Date().toISOString() + '.' + fileExtension;
  }

  saveFileInfo(url: string, fileName: string, modifiedFileName: string, fileDescription: string, userId: string): void {
    this.fbRef = this.af.list('FileUploads/' + userId + '/files');
    const request = {
      'src': url,
      'created': firebase.database.ServerValue.TIMESTAMP,
      'fileName': fileName,
      'modifiedFileName': modifiedFileName,
      'fileDescription': fileDescription
    };
    this.fbRef.push(request);
  }
}
