export class TableHeaders {
car = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'cdfeed': 'guid'
  },
  'make_model': {
    'machine_name': 'field_make_and_model',
    'type': 'tid',
    'validation': ['make_module'],
    'cdfeed': ['make', 'model']
  },
  'model_variant': {
    'machine_name': 'field_model_variant',
    'type': 'value',
    'cdfeed': 'modelVariant'
  },
  'year_of_manufactured': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': ['integer', 'require'],
    'cdfeed': 'manufactureYear'
  },
  'auction_id': {
    'machine_name': 'field_auction_reference',
    'type': 'target_id',
    'requirement': 'lot_number',
    'validation': ['auction_reference', 'requirement']
  },
  'manufactured_month': {
    'machine_name': 'field_manufactured_month',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'manufactureMonth'
  },
  'mileage': {
    'machine_name': 'field_mileage',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'mileage'
  },
  'mileage_type': {
    'machine_name': 'field_mileage',
    'type': 'unit',
    'requirement': 'mileage',
    'validation': ['single_select', 'requirement'],
    'cdfeed': 'mileageUnit'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require', 'country'],
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'chassis_number': {
    'machine_name': 'field_chassis_nr',
    'type': 'value',
    'cdfeed': 'chassisNumber'
  },
  'engine_number': {
    'machine_name': 'field_car_engine_nr',
    'type': 'value',
    'cdfeed': 'engineNumber'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value'
  },
  'car_type': {
    'machine_name': 'field_car_type',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'carType'
  },
  'competition_item': {
    'machine_name': 'field_competition_item',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'competition'
  },
  'fia_papers': {
    'machine_name': 'field_fia_papers',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'fiaPapers'
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': ['float', 'price'],
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'requirement': 'total_price',
    'validation': ['requirement'],
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'priceOnRequest'
  },
  'auction_price_on_request': {
    'machine_name': 'field_price_on_request_lot',
    'type': 'value',
    'validation': ['boolean']
  },
  'low_estimate': {
    'machine_name': 'field_lower_estimate',
    'type': 'amount',
    'requirement': 'lot_number',
    'validation': ['float', 'requirement']
  },
  'low_estimate_currency': {
    'machine_name': 'field_lower_estimate',
    'type': 'currency_code',
    'requirement': 'low_estimate',
    'validation': ['requirement']
  },
  'high_estimate': {
    'machine_name': 'field_higher_estimate',
    'type': 'amount',
    'requirement': 'lot_number',
    'validation': ['float', 'requirement']
  },
  'high_estimate_currency': {
    'machine_name': 'field_higher_estimate',
    'type': 'currency_code',
    'requirement': 'high_estimate',
    'validation': ['requirement']
  },
  'sold_price': {
    'machine_name': 'field_sold_price',
    'type': 'amount',
    'validation': ['float']
  },
  'sold_price_currency': {
    'machine_name': 'field_sold_price',
    'type': 'currency_code'
  },
  'hammer_price': {
    'machine_name': 'field_hammer_price',
    'type': 'amount',
    'validation': ['float']
  },
  'hammer_price_currency': {
    'machine_name': 'field_hammer_price',
    'type': 'currency_code',
    'requirement': 'hammer_price',
    'validation': ['requirement']
  },
  'vat_reclaimable': {
    'machine_name': 'field_vat_reclaimable',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'vat'
  },
  'country_vat': {
    'machine_name': 'field_vat_country',
    'type': 'value',
    'validation': ['require'],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1'
  },
  'vat_value': {
    'machine_name': 'field_vat_value',
    'type': 'value',
    'requirement': 'vat_reclaimable',
    'requirement_value': '1',
    'validation': ['float', 'requirement'],
    'cdfeed': 'vatPercentageAmount'
  },
  'condition': {
    'machine_name': 'field_condition',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'condition'
  },
  'colour': {
    'machine_name': 'field_colour',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'color'
  },
  'metallic': {
    'machine_name': 'field_colour_metallic',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'metallic'
  },
  'exterior_brand_colour': {
    'machine_name': 'field_colour_special',
    'type': 'value',
    'cdfeed': 'brandColor'
  },
  'interior_colour': {
    'machine_name': 'field_car_interior_colour',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'interiorColor'
  },
  'interior_brand_colour': {
    'machine_name': 'field_car_interior_col_special',
    'type': 'value',
    'cdfeed': 'interiorBrandColor'
  },
  'interior_type': {
    'machine_name': 'field_car_interior_type',
    'type': 'value',
    'multiple': 'TRUE',
    'validation': ['multi_select'],
    'cdfeed': 'interiorType',
    'parent_cdfeed': 'interiorTypes'
  },
  'options': {
    'machine_name': 'field_car_optional',
    'type': 'value',
    'multiple': 'TRUE',
    'validation': ['multi_select'],
    'cdfeed': 'carOption',
    'parent_cdfeed': 'carOptions'
  },
  'number_of_seats': {
    'machine_name': 'field_seats_nr',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'seatsNumber'
  },
  'number_of_doors': {
    'machine_name': 'field_car_nr_doors',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'doorsNumber'
  },
  'fuel_type': {
    'machine_name': 'field_car_fuel_type',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'fuelType'
  },
  'gearbox': {
    'machine_name': 'field_car_gearbox',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'gearboxType'
  },
  'performance_quantity': {
    'machine_name': 'field_performance',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'performance'
  },
  'performance_unit': {
    'machine_name': 'field_performance',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'performanceUnit'
  },
  'drivetrain': {
    'machine_name': 'field_car_drive_train',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'driveTrain'
  },
  'car_drive': {
    'machine_name': 'field_car_drive',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'drive'
  },
  'car_description': {
    'machine_name': 'field_car_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'fuel_consumption_combined': {
    'machine_name': 'field_fuel_consumption_combined',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'fuelConsumptionCombined'
  },
  'fuel_consumption_combined_unit': {
    'machine_name': 'field_fuel_consumption_combined',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'fuelConsumptionUnit'
  },
  'extra_fuel_consumption_combined': {
    'machine_name': 'field_extra_u_fuel_consumption',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'extraUrbanFuelConsumption'
  },
  'extra_fuel_consumption_combined_unit': {
    'machine_name': 'field_extra_u_fuel_consumption',
    'type': 'unit',
    'validation': ['single_select']
  },
  'urban_fuel_consumption': {
    'machine_name': 'field_urban_fuel_consumption',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'urbanFuelConsumption'
  },
  'urban_fuel_consumption_unit': {
    'machine_name': 'field_urban_fuel_consumption',
    'type': 'unit',
    'validation': ['single_select']
  },
  'carbon_dioxide_comb': {
    'machine_name': 'field_carbon_dioxide_comb',
    'type': 'quantity',
    'cdfeed': 'co2Emissions'
  },
  'carbon_dioxide_comb_unit': {
    'machine_name': 'field_carbon_dioxide_comb',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'co2EmissionsUnit'
  },
  'co2_efficiency': {
    'machine_name': 'field_co2_class',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'co2EfficiencyClass'
  },
  'recommended_fuel_type': {
    'machine_name': 'field_recommended_fuel',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'recommendedFuelType'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'editorial_title': {
    'machine_name': 'field_editorial_title',
    'type': 'value'
  },
  'lot_number': {
    'machine_name': 'field_lot_nr',
    'type': 'value'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value',
    'validation': ['boolean']
  },
  'contact_anonymous': {
    'machine_name': 'field_contact_anonymous',
    'type': 'value',
    'validation': ['boolean']
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video',
    'type': 'fid'
  }
};

bike = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'cdfeed': 'guid'
  },
  'make_model': {
    'machine_name': 'field_make_and_model',
    'type': 'tid',
    'validation': ['make_module'],
    'cdfeed': ['make', 'model']
  },
  'model_variant': {
    'machine_name': 'field_model_variant',
    'type': 'value',
    'cdfeed': 'modelVariant'
  },
  'lot_number': {
    'machine_name': 'field_lot_nr',
    'type': 'value'
  },
  'year_of_manufactured': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': [
      'integer',
      'require'
    ],
    'cdfeed': 'manufactureYear'
  },
  'manufactured_month': {
    'machine_name': 'field_manufactured_month',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'manufactureMonth'
  },
  'mileage': {
    'machine_name': 'field_mileage',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'mileage'
  },
  'mileage_type': {
    'machine_name': 'field_mileage',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'mileageUnit'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'validation': ['require'],
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require'],
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'chassis_number': {
    'machine_name': 'field_chassis_nr',
    'type': 'value',
    'cdfeed': 'chassisNumber'
  },
  'engine_number': {
    'machine_name': 'field_bike_engine_nr',
    'type': 'value',
    'cdfeed': 'engineNumber'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value'
  },
  'bike_type': {
    'machine_name': 'field_bike_type',
    'type': 'value',
    'validation': [
      'single_select',
      'require'
    ],
    'cdfeed': 'bikeType'
  },
  'competition_item': {
    'machine_name': 'field_competition_item',
    'type': 'value',
    'cdfeed': 'competition'
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'validation': ['require'],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'priceOnRequest'
  },
  'vat_reclaimable': {
    'machine_name': 'field_vat_reclaimable',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'vat'
  },
  'country_vat': {
    'machine_name': 'field_vat_country',
    'type': 'value',
    'validation': ['require'],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1'
  },
  'vat_value': {
    'machine_name': 'field_vat_value',
    'type': 'value',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1',
    'cdfeed': 'vatPercentageAmount'
  },
  'condition': {
    'machine_name': 'field_condition',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'condition'
  },
  'colour': {
    'machine_name': 'field_colour',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'color'
  },
  'exterior_brand_colour': {
    'machine_name': 'field_colour_special',
    'type': 'value',
    'cdfeed': 'brandColor'
  },
  'performance_quantity': {
    'machine_name': 'field_performance',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'performance'
  },
  'performance_unit': {
    'machine_name': 'field_performance',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'performanceUnit'
  },
  'engine_size': {
    'machine_name': 'field_bike_engine_size',
    'type': 'value',
    'cdfeed': 'engineSize'
  },
  'seat_number': {
    'machine_name': 'field_bike_seats_nr',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'seatsNumber'
  },
  'bike_description': {
    'machine_name': 'field_bike_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value'
  },
  'contact_anonymous': {
    'machine_name': 'field_contact_anonymous',
    'type': 'value'
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video',
    'type': 'fid'
  },
  'fuel_consumption_combined': {
    'machine_name': 'field_fuel_consumption_combined',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'fuelConsumptionCombined'
  },
  'fuel_consumption_combined_unit': {
    'machine_name': 'field_fuel_consumption_combined',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'fuelConsumptionUnit'
  },
  'extra_fuel_consumption_combined': {
    'machine_name': 'field_extra_u_fuel_consumption',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'extraUrbanFuelConsumption'
  },
  'extra_fuel_consumption_combined_unit': {
    'machine_name': 'field_extra_u_fuel_consumption',
    'type': 'unit',
    'validation': ['single_select']
  },
  'urban_fuel_consumption': {
    'machine_name': 'field_urban_fuel_consumption',
    'type': 'quantity',
    'validation': ['float'],
    'cdfeed': 'urbanFuelConsumption'
  },
  'urban_fuel_consumption_unit': {
    'machine_name': 'field_urban_fuel_consumption',
    'type': 'unit',
    'validation': ['single_select']
  },
  'carbon_dioxide_comb': {
    'machine_name': 'field_carbon_dioxide_comb',
    'type': 'quantity',
    'cdfeed': 'co2Emissions'
  },
  'carbon_dioxide_comb_unit': {
    'machine_name': 'field_carbon_dioxide_comb',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'co2EmissionsUnit'
  },
  'co2_efficiency': {
    'machine_name': 'field_co2_class',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'co2EfficiencyClass'
  },
  'recommended_fuel_type': {
    'machine_name': 'field_recommended_fuel',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'recommendedFuelType'
  }
};

real_estate = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'cdfeed': 'guid'
  },
  'title': {
    'machine_name': 'title',
    'validation': ['require'],
    'cdfeed': 'title'
  },
  'sale_rent': {
    'machine_name': 'field_re_ownership',
    'type': 'tid',
    'validation': ['single_select'],
    'cdfeed': 'ownership'
  },
  'estate_type': {
    'machine_name': 'field_re_type',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'reType'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'thoroughfare': {
    'machine_name': 'field_location',
    'type': 'thoroughfare',
    'cdfeed': 'street',
    'parent_cdfeed': 'location'
  },
  'postal_code': {
    'machine_name': 'field_location',
    'type': 'postal_code',
    'cdfeed': 'zip',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require', 'country'],
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'estate_sole_agency': {
    'machine_name': 'field_real_estate_sole_agency',
    'type': 'value',
    'validation': ['boolean'],
    'cdfeed': 'reSoleAgency'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value'
  },
  'build_year': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'manufactureYear'
  },
  'renovated_year': {
    'machine_name': 'field_re_renovated',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'renovatedYear'
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'validation': ['require'],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'cdfeed': 'priceOnRequest'
  },
  'pricing_period': {
    'machine_name': 'field_re_pricing_period',
    'type': 'value',
    'validation' : ['require'],
    'dependency': 'sale_rent',
    'dependency_value': '34',
    'cdfeed': 'pricePeriod'
  },
  'rate_amount': {
    'machine_name': 'field_re_rates',
    'type': 'amount',
    'validation': [
      'integer'
    ],
    'cdfeed': 'reRate'
  },
  'rate_currency': {
    'machine_name': 'field_re_rates',
    'type': 'currency_code',
    'cdfeed': 'reRateCurrency'
  },
  'bedrooms': {
    'machine_name': 'field_re_bedrooms',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'reBedrooms'
  },
  'bathrooms': {
    'machine_name': 'field_re_bathrooms',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'reBathrooms'
  },
  'living_area': {
    'machine_name': 'field_re_size',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'reLivingArea'
  },
  'max_guests': {
    'machine_name': 'field_re_guests',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'reGuestsNumber'
  },
  'facilities': {
    'machine_name': 'field_re_facilities',
    'type': 'value',
    'multiple': true,
    'validation': ['multi_select'],
    'cdfeed': 'reFacility',
    'parent_cdfeed': 'reFacilities'
  },
  'lifestyle': {
    'machine_name': 'field_re_lifestyle_specials',
    'type': 'value',
    'multiple': true,
    'validation': ['multi_select'],
    'cdfeed': 'reLifestyle',
    'parent_cdfeed': 'reLifestyleItems'
  },
  'description': {
    'machine_name': 'field_real_estate_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video'
  },
  'energy_certification': {
    'machine_name': 'field_re_energy_certification',
    'type': 'value',
    'cdfeed': 'reEnergyCertification'
  },
  'energy_source': {
    'machine_name': 'field_re_energy_source',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'dependency': 'energy_certification',
    'dependency_value': '1',
    'cdfeed': 'reEnergySource'
  },
  'type_of_energy_certificate': {
    'machine_name': 'field_re_type_of_certificate',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'dependency': 'energy_certification',
    'dependency_value': '1',
    'cdfeed': 'reEnergyCertificateType'
  },
  'energy_consumption': {
    'machine_name': 'field_re_energy_consumption',
    'type': 'value',
    'validation': [
      'require',
      'float'
    ],
    'dependency': 'energy_certification',
    'dependency_value': '1',
    'cdfeed': 'reEnergyConsumption'
  }
};

watch = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'cdfeed': 'guid'
  },
  'make_model': {
    'machine_name': 'field_make_and_model',
    'type': 'tid',
    'validation': ['make_module'],
    'cdfeed': ['make', 'model']
  },
  'model_variant': {
    'machine_name': 'field_model_variant',
    'type': 'value',
    'cdfeed': 'modelVariant'
  },
  'lot_number': {
    'machine_name': 'field_lot_nr',
    'type': 'value'
  },
  'year_of_manufactured': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': ['require', 'integer'],
    'cdfeed': 'manufactureYear'
  },
  'manufactured_month': {
    'machine_name': 'field_manufactured_month',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'manufactureMonth'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require'],
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value'
  },
  'watch_type': {
    'machine_name': 'field_watch_type',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'watchType'
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'validation': ['require'],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'priceOnRequest'
  },
  'vat_reclaimable': {
    'machine_name': 'field_vat_reclaimable',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'vat'
  },
  'country_vat': {
    'machine_name': 'field_vat_country',
    'type': 'value',
    'validation': ['require'],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1'
  },
  'vat_value': {
    'machine_name': 'field_vat_value',
    'type': 'value',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1',
    'cdfeed': 'vatPercentageAmount'
  },
  'movement': {
    'machine_name': 'field_watch_movement',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'watchMovement'
  },
  'diameter': {
    'machine_name': 'field_watch_diameter',
    'type': 'value',
    'validation': ['integer'],
    'cdfeed': 'watchDiameter'
  },
  'case_material': {
    'machine_name': 'field_watch_case_mat',
    'type': 'value',
    'validation': [
      'single_select'
    ],
    'cdfeed': 'watchCaseMaterial'
  },
  'bracelet_materal': {
    'machine_name': 'field_watch_bracelet_mat',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'watchBraceletMaterial'
  },
  'watch_description': {
    'machine_name': 'field_watch_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value'
  },
  'contact_anonymous': {
    'machine_name': 'field_contact_anonymous',
    'type': 'value'
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video',
    'type': 'fid'
  }
};

yacht = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'cdfeed': 'guid'
  },
  'make_model': {
    'machine_name': 'field_make_and_model',
    'type': 'tid',
    'validation': ['make_module'],
    'cdfeed': ['make', 'model']
  },
  'model_variant': {
    'machine_name': 'field_model_variant',
    'type': 'value',
    'cdfeed': 'modelVariant'
  },
  'lot_number': {
    'machine_name': 'field_lot_nr',
    'type': 'value',
    'cdfeed': 'lotNumber'
  },
  'year_of_manufactured': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': ['integer', 'require'],
    'cdfeed': 'manufactureYear'
  },
  'manufactured_month': {
    'machine_name': 'field_manufactured_month',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'manufactureMonth'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'validation': ['require'],
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require'],
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'boat_type': {
    'machine_name': 'field_boat_type',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'boatType'
  },
  'boat_category': {
    'machine_name': 'field_boat_category',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'boatCategory'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value'
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'validation': ['require'],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'priceOnRequest'
  },
  'vat_reclaimable': {
    'machine_name': 'field_vat_reclaimable',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'vat'
  },
  'country_vat': {
    'machine_name': 'field_vat_country',
    'type': 'value',
    'validation': ['require'],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1'
  },
  'vat_value': {
    'machine_name': 'field_vat_value',
    'type': 'value',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1',
    'cdfeed': 'vatPercentageAmount'
  },
  'condition': {
    'machine_name': 'field_condition',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'condition'
  },
  'hull_material': {
    'machine_name': 'field_boat_hull_mat',
    'type': 'value',
    'validation': [
      'require',
      'single_select'
    ],
    'cdfeed': 'boatHullMaterial'
  },
  'engines': {
    'machine_name': 'field_boat_engines',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'enginesNumber'
  },
  'cabins': {
    'machine_name': 'field_boat_berths',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'cabinsNumber'
  },
  'beam': {
    'machine_name': 'field_boat_beam',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'boatBeam'
  },
  'beam_unit': {
    'machine_name': 'field_boat_beam',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'beamUnit'
  },
  'draft': {
    'machine_name': 'field_boat_draft',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'boatDraft'
  },
  'draft_unit': {
    'machine_name': 'field_boat_draft',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'draftUnit'
  },
  'length_over_all': {
    'machine_name': 'field_boat_loa',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'boatLengthOverall'
  },
  'length_over_all_unit': {
    'machine_name': 'field_boat_loa',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'lengthUnit'
  },
  'displacement': {
    'machine_name': 'field_boat_displacement',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'boatDisplacement'
  },
  'displacement_unit': {
    'machine_name': 'field_boat_displacement',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'boatDisplacementUnit'
  },
  'performance': {
    'machine_name': 'field_performance',
    'type': 'quantity',
    'validation': ['integer'],
    'cdfeed': 'performance'
  },
  'performance_unit': {
    'machine_name': 'field_performance',
    'type': 'unit',
    'validation': ['single_select'],
    'cdfeed': 'performanceUnit'
  },
  'engine_model': {
    'machine_name': 'field_boat_engines_model',
    'type': 'value',
    'cdfeed': 'enginesModel'
  },
  'engine_hours': {
    'machine_name': 'field_boat_engines_hours',
    'type': 'value',
    'cdfeed': 'enginesHours'
  },
  'cruising_speed': {
    'machine_name': 'field_boat_cruising_speed',
    'type': 'value',
    'cdfeed': 'cruisingSpeed'
  },
  'max_speed': {
    'machine_name': 'field_boat_max_speed',
    'type': 'value',
    'cdfeed': 'maxSpeed'
  },
  'interior': {
    'machine_name': 'field_boat_int_designer',
    'type': 'value',
    'cdfeed': 'interiorDesigner'
  },
  'fuel_tankage': {
    'machine_name': 'field_boat_fuel_tankage',
    'type': 'value',
    'cdfeed': 'fuelTankage'
  },
  'water_tankage': {
    'machine_name': 'field_boat_water_tankage',
    'type': 'value',
    'cdfeed': 'waterTankage'
  },
  'colour': {
    'machine_name': 'field_colour',
    'type': 'value',
    'cdfeed': 'color'
  },
  'exterior_brand_colour': {
    'machine_name': 'field_colour_special',
    'type': 'value',
    'validation': ['single_select'],
    'cdfeed': 'brandColor'
  },
  'boat_description': {
    'machine_name': 'field_boat_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value'
  },
  'contact_anonymous': {
    'machine_name': 'field_contact_anonymous',
    'type': 'value'
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video',
    'type': 'fid'
  }
};

art_collectibles = {
  'guid': {
    'machine_name': 'field_external_id',
    'type': 'value',
    'csv_key': 0,
    'cdfeed': 'guid'
  },
  'title': {
    'machine_name': 'title',
    'validation': ['require'],
    'csv_key': 1,
    'cdfeed': 'title'
  },
  'art_type': {
    'machine_name': 'field_art_collectible',
    'type': 'tid',
    'validation': ['single_select'],
    'csv_key': 2,
    'cdfeed': 'collectibleType'
  },
  'year_of_manufactured': {
    'machine_name': 'field_manufactured_year',
    'type': 'value',
    'validation': ['integer'],
    'csv_key': 3,
    'cdfeed': 'manufactureYear'
  },
  'manufactured_month': {
    'machine_name': 'field_manufactured_month',
    'type': 'value',
    'validation': ['single_select'],
    'csv_key': 4,
    'cdfeed': 'manufactureMonth'
  },
  'locality': {
    'machine_name': 'field_location',
    'type': 'locality',
    'validation': ['require'],
    'csv_key': 5,
    'cdfeed': 'city',
    'parent_cdfeed': 'location'
  },
  'administrative_area': {
    'machine_name': 'field_location',
    'type': 'administrative_area',
    'csv_key': 6,
    'cdfeed': 'state',
    'parent_cdfeed': 'location'
  },
  'country': {
    'machine_name': 'field_location',
    'type': 'country',
    'validation': ['require'],
    'csv_key': 7,
    'cdfeed': 'country',
    'parent_cdfeed': 'location'
  },
  'client_reference_number': {
    'machine_name': 'field_client_ref_nr',
    'type': 'value',
    'csv_key': 8
  },
  'total_price': {
    'machine_name': 'field_total_price',
    'type': 'amount',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'csv_key': 9,
    'cdfeed': 'price'
  },
  'total_price_currency': {
    'machine_name': 'field_total_price',
    'type': 'currency_code',
    'validation': ['require'],
    'dependency': 'price_on_request',
    'dependency_value': '0',
    'csv_key': 10,
    'cdfeed': 'currency'
  },
  'price_on_request': {
    'machine_name': 'field_price_on_request',
    'type': 'value',
    'validation': ['require'],
    'csv_key': 11,
    'cdfeed': 'priceOnRequest'
  },
  'vat_reclaimable': {
    'machine_name': 'field_vat_reclaimable',
    'type': 'value',
    'validation': ['require'],
    'cdfeed': 'vat'
  },
  'country_vat': {
    'machine_name': 'field_vat_country',
    'type': 'value',
    'validation': ['require'],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1'
  },
  'vat_value': {
    'machine_name': 'field_vat_value',
    'type': 'value',
    'validation': [
      'require',
      'integer'
    ],
    'dependency': 'vat_reclaimable',
    'dependency_value': '1',
    'cdfeed': 'vatPercentageAmount'
  },
  'contact_email': {
    'machine_name': 'field_contact_email',
    'type': 'email',
    'cdfeed': 'email'
  },
  'first_name': {
    'machine_name': 'field_contact_first_name',
    'type': 'value',
    'cdfeed': 'first_name'
  },
  'last_name': {
    'machine_name': 'field_contact_last_name',
    'type': 'value',
    'cdfeed': 'last_name'
  },
  'phone_number': {
    'machine_name': 'field_contact_phone',
    'type': 'number',
    'cdfeed': 'phone_number'
  },
  'phone_number_country': {
    'machine_name': 'field_contact_phone',
    'type': 'country_codes',
    'cdfeed': 'phone_number_country'
  },
  'show_number': {
    'machine_name': 'field_contact_show_phone',
    'type': 'value'
  },
  'contact_anonymous': {
    'machine_name': 'field_contact_anonymous',
    'type': 'value'
  },
  'collectible_description': {
    'machine_name': 'field_art_collect_description',
    'type': 'value',
    'cdfeed': 'description'
  },
  'images': {
    'machine_name': 'field_add_images',
    'type': 'fid',
    'multiple': 'TRUE',
    'validation': ['require'],
    'cdfeed': 'image',
    'parent_cdfeed': 'images'
  },
  'videos': {
    'machine_name': 'field_video',
    'type': 'fid'
  },
  'lot_number': {
    'machine_name': 'field_lot_nr',
    'type': 'value'
  },
  'auction_id': {
    'machine_name': 'field_auction_reference',
    'type': 'target_id',
    'requirement': 'lot_number',
    'validation': ['auction_reference', 'requirement']
  },
  'low_estimate': {
    'machine_name': 'field_lower_estimate',
    'type': 'amount',
    'requirement': 'lot_number',
    'validation': ['float', 'requirement']
  },
  'low_estimate_currency': {
    'machine_name': 'field_lower_estimate',
    'type': 'currency_code',
    'requirement': 'low_estimate',
    'validation': ['requirement']
  },
  'high_estimate': {
    'machine_name': 'field_higher_estimate',
    'type': 'amount',
    'requirement': 'lot_number',
    'validation': ['float', 'requirement']
  },
  'high_estimate_currency': {
    'machine_name': 'field_higher_estimate',
    'type': 'currency_code',
    'requirement': 'high_estimate',
    'validation': ['requirement']
  }
};

}
