import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseLogMessage'
})
export class ParseLogMsg implements PipeTransform {

  transform(value: string): any {
    const parsedValue = JSON.parse(value);
    return parsedValue.msg;
  }
}
