import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserPrivilegeService } from '../../services/userPrivilege/user-privilege.service';
import { CdxmlService } from '../../services/cdxml/cdxml.service';
import { CdApiService } from '../../services/cdApi/cd-api.service';
import { UtilityService } from '../../services/utils/utility.service';
import 'rxjs/add/operator/map';
import { AngularFireList } from '@angular/fire/database';
import { CdxmlRequest } from './../../models';
import * as firebase from 'firebase/app';
import {normalUser, viewSellerId} from '../../models/userPrivilege.config';

@Component({
  selector: 'app-import-validate',
  templateUrl: './import-validate.component.html',
  styleUrls: ['./import-validate.component.css'],
  providers: [UserPrivilegeService, CdxmlService, CdApiService, UtilityService]
})
export class ImportValidateComponent implements OnInit, OnChanges {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  cdXmlForm: FormGroup;
  hasPrivilege = false;
  hasSpecialPrivilege = false;
  progressDetails = false;
  validationCount: Array<number> = [];
  showErrorInValidation: string;
  showContentTypes = false;
  disableValidateButton = false;
  hideResetButton = true;
  viewLogs = false;
  logButton = false;
  importValidationButtonLabel = 'Validate';
  requestId: string;
  convertedResponseId: string;
  importOptions = [
    {value: 'validationBasic', viewValue: 'Basic Validation'},
    {value: 'validationFull', viewValue: 'Full Validation'},
    {value: 'validateAndImport', viewValue: 'Validate and Import'}
  ];
  fileTypes = [
    {value: 'cdxml', viewValue: 'CDXML'},
    {value: 'csv', viewValue: 'CSV'},
    {value: 'xls', viewValue: 'Excel'}
  ];
  contentTypes = [
    {value: 'bike', viewValue: 'Bikes'},
    {value: 'car', viewValue: 'Cars'},
    {value: 'art_collectibles', viewValue: 'Collectibles'},
    {value: 'real_estate', viewValue: 'Real Estate'},
    {value: 'watch', viewValue: 'Watches'},
    {value: 'yacht', viewValue: 'Yachts'}
  ];
  importResponseObservable: AngularFireList<any[]>;
  importResponse: Array<any>;
  currentEnv = localStorage.getItem('currentEnv') || null;
  @Input() copyUrlFromFileHistory?: string;
  @Output() conversionResponseReceived = new EventEmitter<string>();
  @Output() importValidateStatusMsg = new EventEmitter<string>();
  @Output() importResponseReceived = new EventEmitter<Object>();

  constructor(private fb: FormBuilder, private ups: UserPrivilegeService, private cdxml: CdxmlService, private utils: UtilityService) {
  }

  ngOnInit() {
    this.buildForm();
    this.userPrivilege();
    this.userSpecialPrivilege();
  }

  ngOnChanges() {
    this.buildForm();
    this.setResetButtonDisplay();
  }

  resetForm() {
    this.cdXmlForm.reset();
    this.buildForm();
    this.setResetButtonDisplay();
    this.showErrorInValidation = null;
    this.showContentTypes = false;
    this.logButton = false;
    this.viewLogs = false;
    this.importResponse = null;
  }

  buildForm(): void {
    this.cdXmlForm = this.fb.group({
      'cdXmlUrl': [this.copyUrlFromFileHistory, [
        Validators.required,
        Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
        ]
      ],
      'sellerId': [null, [
        this.makeSellerIdRequired.bind(this)
      ]],
      'importProcess': [this.importOptions[0].value, [
        Validators.required]
      ],
      'fileType': [this.fileTypes[0].value, [
        Validators.required]
      ],
      'contentType': [this.contentTypes[1].value,
        [
          this.validFileType.bind(this)
        ]],
    });
  }

  selectImportProcess(event: any): void {
    if (event.value === 'validateAndImport') {
      this.importValidationButtonLabel = 'Import';
    }
    else {
      this.importValidationButtonLabel = 'Validate';
    }
  }

  userPrivilege(): void {
    const privilege = this.ups.userPrivilege(normalUser);
    if (privilege) {
      this.hasPrivilege = true;
    }
  }

  userSpecialPrivilege(): void {
    const privilege = this.ups.userPrivilege(viewSellerId);
    if (privilege) {
      this.hasSpecialPrivilege = true;
    }
  }

  onSubmit(): void {
    this.importResponse = null;
    this.disableValidateButton = true;
    this.hideResetButton = true;
    this.showErrorInValidation = null;
    this.logButton = true;
    this.viewLogs = true;
    const user = this.cdXmlForm.value;
    this.getFormInputs(user);
  }

  getFormInputs(formData: any): void {
    let formInputs: CdxmlRequest;
    if (formData) {
      formInputs = {
        targetUrl: formData.cdXmlUrl,
        importProcess: formData.importProcess,
        env: this.currentEnv,
        fileType: formData.fileType,
        contentType: formData.contentType,
        status: {
          status: 'requested',
          attempts: 0,
          created: firebase.database.ServerValue.TIMESTAMP,
          lastUpdated: firebase.database.ServerValue.TIMESTAMP
        }
      };
      if (formData.sellerId) {
        formInputs.sellerId = formData.sellerId;
      }
      else {
        formInputs.sellerId = this.currentUser.account.uid;
      }
    }
    this.addToFirebase(formInputs);
  }

  addToFirebase(formInputs: CdxmlRequest): void {
    formInputs.env = this.currentEnv;
    this.requestId = this.cdxml.addToFirebase(formInputs);
    this.getImportStatus();
    this.getConversionResponseFromFirebase();
  }

  getImportStatus(): void {
    const requestStatus = this.cdxml.getResponseAsObject('Requests/' + this.currentUser.account.uid + '/' + this.requestId + '/status');
    requestStatus.valueChanges().subscribe(status => {
      if (status && status.status && status.status === 'queueing') {
        this.conversionResponseReceived.emit();
        this.importValidateStatusMsg.emit('Conversion Process Complete');
      }
    }, (error) => {
      console.log(error);
    });
  }

  getConversionResponseFromFirebase(): void {
    this.showErrorInValidation = null;
    this.validationCount = [];
    const conversionResponse = this.cdxml.getResponseAsObject('ConvertedData/' + this.currentUser.account.uid + '/' + this.requestId);
    conversionResponse.valueChanges().subscribe(res => {
      if (res) {
        const convertToArray = this.utils.convertObjectToArray(res);
        this.handleConversionResponse(convertToArray);
      }
    }, (error) => {
      console.log(error);
      this.hideProgressDetails();
    });
  }

  handleConversionResponse(response: Array<any>): void {
    if (response.length > 0) {
      this.convertedResponseId = response[0].key;
      this.getImportResponse();
      this.validationCount[<any>'total'] = Object.keys(response[0]).length - 1;
      if (response[0].error) {
        this.showErrorInValidation = response[0].error;
        this.hideProgressDetails();
        this.importValidateStatusMsg.emit('Error Occurred: ' + response[0].error);
      }
    }
    else if (response.length === 0) {
      this.importValidateStatusMsg.emit('Waiting for conversion');
      return; // waiting on convert response on firebase
    }
  }

  getImportResponse() {
    this.importResponseObservable = this.cdxml.getResponseAsList('ImportResponse/' + this.currentUser.account.uid + '/' + this.requestId + '/' + this.convertedResponseId);
    this.importResponseObservable.valueChanges().subscribe(response => {
      if (response.length > 0) {
        this.importResponseReceived.emit({
          'userId': this.currentUser.account.uid,
          'requestId': this.requestId,
          'convertedResponseId': this.convertedResponseId
        });
        this.importResponse = response;
        this.validationCount[<any>'imported'] = response.length;
        this.showProgressDetails();
      }
    }, (error) => {
      console.log(error);
      this.hideProgressDetails();
    });
  }

  showProgressDetails(): void {
    this.progressDetails = true;
    if (this.validationCount[<any>'imported'] === this.validationCount[<any>'total']) {
      this.importValidateStatusMsg.emit('Import/Validation Complete');
      this.hideProgressDetails();
    }
  }

  hideProgressDetails(): void {
    this.progressDetails = false;
    this.setResetButtonDisplay();
    this.disableValidateButton = false;
  }

  shouldDisableValidateButton(): void {
    this.disableValidateButton = this.cdXmlForm.invalid;
  }

  setResetButtonDisplay(): void {
    this.hideResetButton = this.shouldHideResetButton();
  }

  shouldHideResetButton(): boolean {
    if (this.cdXmlForm.value.cdXmlUrl || this.cdXmlForm.value.sellerId) {
      return false;
    }
    return true;
  }

  validFileType(control: FormControl): {[s: string]: boolean} {
    if (!this.cdXmlForm) {
      return {'noContentTypeSelected': true};
    }
    const fileType = this.cdXmlForm.value.fileType;
    const contentType = control.value;
    if (fileType !== 'cdxml' && contentType === '') {
      return { 'noContentTypeSelected': true };
    }
    return null;
  }

  onFileTypeChange(event: any): void {
    this.showContentTypes = event.value !== 'cdxml';
  }

  makeSellerIdRequired(control: FormControl): {[s: string]: boolean} {
    if (!this.cdXmlForm) {
      return {'invalidSellerId': true};
    }
    const sellerId = control.value;
    if (this.hasSpecialPrivilege && !sellerId) {
      return {'invalidSellerId': true};
    }
    return null;
  }
}
