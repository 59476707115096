import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {  AppComponent,
          SigninComponent,
          NavbarComponent,
          HomeComponent,
          LogoutComponent,
          ProgressComponent,
          UploadFileComponent,
          ImportHistoryComponent,
          ImportValidateComponent,
          FileHistoryComponent,
          ValidationTableComponent,
          WelcomeMessageComponent,
          CurrentEnvComponent,
          LogTableComponent,
          ToolsComponent,
          ImportQueueComponent
} from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { CdApiService, ConfigService } from './services';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthGuard } from './guard/auth.guard';
import { KeyvaluePipe } from './filters/keyvalue.pipe';
import { ParseLogMsg } from './filters/parseLogMsg.pipe';
import { ArrayObjectLength } from './filters/arrayOrObjectLength.pipe';
import { FormValidatorComponent } from './widgets';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { DataTableModule } from 'angular2-datatable';
import { LogoComponent } from './widgets';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    NavbarComponent,
    HomeComponent,
    LogoutComponent,
    ProgressComponent,
    KeyvaluePipe,
    ArrayObjectLength,
    FormValidatorComponent,
    UploadFileComponent,
    FileHistoryComponent,
    ImportHistoryComponent,
    ValidationTableComponent,
    ImportValidateComponent,
    WelcomeMessageComponent,
    CurrentEnvComponent,
    LogTableComponent,
    LogoComponent,
    ParseLogMsg,
    ToolsComponent,
    ImportQueueComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    DataTableModule,
    RouterModule.forRoot([
      {
        path: 'login',
        component: SigninComponent
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '',
        component: ProgressComponent
      },
      {
        path: 'upload',
        component: UploadFileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'import-queue',
        component: ImportQueueComponent,
        canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: '' }
    ])
  ],
  providers: [AuthenticationService, AuthGuard, CdApiService, ConfigService, AngularFireAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }
