import { UserLogin, User } from './../../models/user-login.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { CdApiService } from '../';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';

@Injectable()
export class AuthenticationService {
  env: string;
  authenticated = false;
  userFromFB: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private cdapi: CdApiService, private af: AngularFireAuth, private db: AngularFireDatabase) {
    this.setBaseUrl();
    this.af.auth.onAuthStateChanged((user: any) => {
      this.authenticated = false;
      if (!user) {
        this.logoutIfLoggedIn();
      }
      else {
        this.authenticated = true;
      }
    });
    this.getDataFromFirebase(); // only used to store the user roles in the database
  }

  getDataFromFirebase() {
    this.af.authState.subscribe(auth => {
      if (auth) {
        /// signed in
        return this.db.object('Users/' + auth.uid);
      }
      else {
        /// not signed in
        return Observable.of(null);
      }
    });
  }

  logoutIfLoggedIn() {
    this.env = localStorage.getItem('currentEnv') || null;
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) || null;
    if (this.env && currentUser) {
      this.logout();
    }
  }

  setBaseUrl(): void {
    this.env = localStorage.getItem('currentEnv') || null;
    this.cdapi.baseUrl = localStorage.getItem('envUrl') || null;
    this.cdapi.lang = 'en';
  }

  authenticate(user: UserLogin): Observable<any> {
    this.setBaseUrl();
    const token = localStorage.getItem('csrfToken');
    const body = JSON.stringify({email: user.email, password: user.password});
    this.cdapi.setHeaderValue('Content-Type', 'application/json');
    this.cdapi.setHeaderValue('X-CSRF-Token', token);
    this.cdapi.setBody(body);
    const response = this.cdapi.callHttpPost('/api/user/sign_in');
    return response;
  }

  authenticateFirebase(token: string): any {
    return this.af.auth.signInWithCustomToken(token);
  }

  saveUserInfo(data: any): boolean {
    if (data) {
      localStorage.setItem('currentUser', JSON.stringify({
        token: data.token,
        sessid: data.sessid,
        session_name: data.session_name,
        account: data.user.account,
        profile: data.user.profile,
        loggedIn: true
      }));
      const userData = {
        'uid': data.user.account.uid,
        'email': data.user.account.email.value,
        'roles': data.user.account.roles
      };
     this.updateUserRoles(userData);
      return true;
    }
    else {
      return false;
    }
  }

  updateUserRoles(authData: any) {
    const userData = new User(authData);
    const ref = this.db.object('Users/' + authData.uid);
    ref.valueChanges().subscribe((user: any) => {
      if (user == null) {
        ref.update(userData);
      }
    });
  }

  logout(): Promise<Observable<any>> {
    localStorage.removeItem('csrfToken');
    localStorage.removeItem('currentUser');
    return this.getToken().then(token => {
      return this.logoutFromBothEnvs(token);
    });
  }

  logoutFromBothEnvs(token?: string) {
    this.cdapi.setHeaderValue('Content-Type', 'application/json');
    this.cdapi.setHeaderValue('X-CSRF-Token', token);
    this.cdapi.setBody(null);
    const response = this.cdapi.callHttpPost('/api/user/logout');
    response.subscribe(done => {
      const loggedOut = done.json();
      if (loggedOut) {
        this.logoutFromFirebase();
      }
    });
    return response;
  }

  logoutFromFirebase() {
    this.af.auth.signOut();
  }

  async getToken(): Promise<any> {
    this.setBaseUrl();
    return await this.cdapi.callHttpGet('/services/session/token').toPromise()
    .then((res) => res._body);
  }

  checkLoggedIn(token: string): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if (currentUser.token === token) {
        return true;
      }
      localStorage.removeItem('currentUser');
      return false;
    }
    return false;
  }

  getCurrentUser(): Observable<any> {
    const token = localStorage.getItem('csrfToken');
    this.cdapi.setToken(token);
    this.cdapi.setHeaderValue('Content-Type', 'application/json');
    this.cdapi.setBody(null);
    return this.cdapi.callHttpPost('/api/system/connect');
  }

  showLogoutButton(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser != null && currentUser.loggedIn === true;
  }
}
