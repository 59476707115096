import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
  public static OPTION_SORT_ASC = 'ASC';
  public static OPTION_SORT_DESC = 'DESC';

  constructor() { }

  sort(data: Array<any>, property: string, order?: string, type?: string ): Array<any> {
    switch (type) {
      case 'date':
        return this.sortDate(data, property, order);
      case 'text':
        return this.sortText(data, property, order);
      case 'number':
        return this.sortNumbers(data, property, order);
      default:
        return this.sortDefault(data, property);
    }
  }

  sortDate(data: Array<any>, property: string, order?: string): Array<any> {
    return data.sort((a, b) => {
      if (UtilityService.OPTION_SORT_DESC === order.toUpperCase()) {
        return new Date(a[property]).getTime() - new Date(b[property]).getTime();
      }
      return new Date(b[property]).getTime() - new Date(a[property]).getTime();  // default is UtilityService.OPTION_SORT_ASC
    });
  }

  sortText(data: Array<any>, property: string, order?: string): Array<any> {
    return data.sort((a, b) => {
      if (UtilityService.OPTION_SORT_DESC === order.toUpperCase()) {
        return a[property].toUpperCase() - b[property].toUpperCase();
      }
      return b[property].toUpperCase() - a[property].toUpperCase();  // default is UtilityService.OPTION_SORT_ASC
    });
  }

  sortNumbers(data: Array<any>, property: string, order?: string): Array<any> {
    return data.sort((a, b) => {
      if (UtilityService.OPTION_SORT_DESC === order.toUpperCase()) {
        return a[property] - b[property];
      }
      return b[property] - a[property];  // default is UtilityService.OPTION_SORT_ASC
    });
  }

  sortDefault(data: Array<any>, property: string): Array<any> {
    return data.sort((a, b) => {
      return b[property] - a[property];
    });
  }

  convertObjectToArray(data: Object): Array<any> {
    if (typeof data !== 'undefined') {
      const keys: Array<any> = Object.keys(data);
      return keys.map((key: string) => {
        return {...data[ key], key };
      });
    }
    return [];
  }
}
