import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.css']
})
export class WelcomeMessageComponent implements OnInit {
  currentUser: any;
  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.setCurrentUser(this.currentUser);
    }
  }

  ngOnInit() {
  }

  setCurrentUser(currentUser: any): void {
    this.currentUser = currentUser;
  }
}
