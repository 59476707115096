import { Component, OnInit, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { TableHeaders } from '../../models/cdResources';
import { AngularFireList, AngularFireObject } from '@angular/fire/database';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CdxmlService } from '../../services/index';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-validation-table',
  templateUrl: './validation-table.component.html',
  styleUrls: ['./validation-table.component.css'],
  providers: [CdxmlService]
})
export class ValidationTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userId?: string;
  @Input() requestId?: string;
  @Input() convertedResponseId?: string;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  importResponseDataSource: MatTableDataSource<any>;
  tableHeaders: Array<any>;
  customHeaders = ['data', 'import-errors', 'matching-request'];
  summary: AngularFireObject<any>;
  filterForm: FormGroup;
  showJson = false;
  responseSummary: Object;
  rowStatus: string;
  columnStatus: string;
  errorShowingImportValidateTable = false;
  showSpinner = false;
  rowStatusOptions = [
    {value: 'all', viewValue: 'Show all rows'},
    {value: 'errorRows', viewValue: 'Show only error rows'}
  ];
  columnStatusOptions = [
    {value: 'allColumns', viewValue: 'Show all columns'},
    {value: 'errorColumns', viewValue: 'Show only error columns'}
  ];
  offset = 10;
  nextKey: any;
  prevKeys: any[] = [];
  listings: any;
  pageIndex = 0;
  pageLength = 0;
  importResponseSubscription: any;
  importSummarySubscription: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private fb: FormBuilder, private cdxml: CdxmlService) {
    this.showResponseInfo();
  }

  ngOnInit() {
    this.setDefaultFilterOptions();
    this.buildForm();
    this.showResponseInfo();
    this.showImportValidateResponse();
  }

  ngOnChanges() {
    this.showResponseInfo();
    this.showImportValidateResponse();
  }

  ngOnDestroy() {
    this.importResponseSubscription.unsubscribe();
  }

  setDefaultFilterOptions() {
    this.rowStatus = this.rowStatusOptions[1].value;
    this.columnStatus = this.columnStatusOptions[1].value;
  }

  showResponseInfo(): void {
    if (this.requestId && this.userId && this.convertedResponseId) {
      this.summary = this.cdxml.getResponseAsObject('ImportSummary/' + this.userId + '/' + this.requestId + '/' + this.convertedResponseId);
      this.importSummarySubscription = this.summary.valueChanges().subscribe(
        response => {
          this.responseSummary = response;
          if (this.responseSummary && this.responseSummary['numTotalRows'] != null && typeof this.responseSummary['numTotalRows'] !== 'undefined') {
            this.pageLength = this.responseSummary['numTotalRows'];
          }
          else {
            this.pageLength = 0;
          }
          if (this.responseSummary && this.responseSummary['importedItems'] != null && typeof this.responseSummary['importedItems'] === 'object') {
            this.responseSummary['noOfItemsImported'] = Object.keys(this.responseSummary['importedItems']).length;
          }
          else if (this.responseSummary && this.responseSummary['importedItems'] != null && Array.isArray(this.responseSummary['importedItems'])) {
            this.responseSummary['noOfItemsImported'] = this.responseSummary['importedItems'].length;
          }
          if (this.responseSummary && !this.responseSummary['numErrorRows'] && this.responseSummary['numErrorRows'] == null) {
            this.responseSummary['numErrorRows'] = 0;
          }
        }
      );
    }
  }

  buildForm(): void {
    this.filterForm = this.fb.group({
      'rowStatuses': [this.rowStatusOptions[1].value, []],
      'columnStatuses': [this.columnStatusOptions[1].value, []],
    });
  }

  onRowChange(): void {
    this.rowStatus = this.filterForm.value.rowStatuses;
    this.showImportValidateResponse();
  }

  onColumnChange(): void {
    this.columnStatus = this.filterForm.value.columnStatuses;
    this.showImportValidateResponse();
  }

  onPaginateChange(event: any) {
    if (this.pageIndex < event.pageIndex) {
      this.nextPage();
    }
    else {
      this.prevPage();
    }
    this.pageIndex = event.pageIndex;
  }

  nextPage() {
    this.prevKeys.push(_.first(this.listings)['$key']);
    this.showImportValidateResponse(this.nextKey);
  }

  prevPage() {
    const prevKey = _.last(this.prevKeys);
    this.prevKeys = _.dropRight(this.prevKeys);
    this.showImportValidateResponse(prevKey);
  }

  showImportValidateResponse(key?: string): void {
    this.showSpinner = true;
    if (this.requestId) {
      const fbRef = 'ImportResponse/' + this.userId + '/' + this.requestId + '/' + this.convertedResponseId;
      const importResponseObservable: AngularFireList<any> = this.cdxml.getDataForPagination(fbRef, this.offset, key);
      this.importResponseSubscription = importResponseObservable.snapshotChanges().subscribe(
        response => {
          const convertedData = response.map(r => ({$key: r.key, ...r.payload.val()}));
          if (convertedData.length > 0) {
            this.listings = _.slice(convertedData, 0, this.offset);
            this.nextKey = _.get(convertedData[this.offset], '$key');
            this.filterBasedOnStatus(this.listings);
            this.showSpinner = false;
          }
        },
        (error) => {
          this.errorShowingImportValidateTable = true;
          console.log(error);
          this.showSpinner = false;
        },
        () => {
          this.importResponseSubscription.unsubscribe();
        });
    }
  }

  filterBasedOnStatus(response: Array<any>): void {
    this.importResponseDataSource = new MatTableDataSource([]);
    if (this.rowStatus === 'errorRows' && this.columnStatus !== 'errorColumns') {
      const filteredResponse = this.filterErrorRows(response);
      this.importResponseDataSource = new MatTableDataSource(filteredResponse);
      if (filteredResponse.length > 0) {
        this.setTableHeaders(filteredResponse);
      }
    }
    else if (this.rowStatus !== 'errorRows' && this.columnStatus === 'errorColumns') {
      this.importResponseDataSource = new MatTableDataSource(this.filterErrorColumns(response));
    }
    else if (this.rowStatus === 'errorRows' && this.columnStatus === 'errorColumns') {
      const items = this.filterErrorRows(response);
      this.importResponseDataSource = new MatTableDataSource(this.filterErrorColumns(items));
    }
    else {
      this.importResponseDataSource = new MatTableDataSource(response);
      if (this.importResponseDataSource.data.length > 0) {
        this.setTableHeaders(response);
      }
    }
    this.importResponseDataSource.paginator = undefined;
  }

  setTableHeaders(response: any): void {
    if (response.length > 0) {
      this.tableHeaders = null;
      const type = response[0].data.row.type;
      this.findContentTypeForHeaders(type);
      this.tableHeaders = this.customHeaders.concat(this.tableHeaders);
    }
    else {
      this.tableHeaders = null;
    }
  }

  findContentTypeForHeaders(type: string): void {
    const headers = new TableHeaders;
    switch (type) {
      case 'car': {
        this.tableHeaders = Object.keys(headers.car).sort();
        break;
      }
      case 'bike': {
        this.tableHeaders = Object.keys(headers.bike).sort();
        break;
      }
      case 'watch': {
        this.tableHeaders = Object.keys(headers.watch).sort();
        break;
      }
      case 'real_estate': {
        this.tableHeaders = Object.keys(headers.real_estate).sort();
        break;
      }
      case 'yacht': {
        this.tableHeaders = Object.keys(headers.yacht).sort();
        break;
      }
      case 'art_collectibles': {
        this.tableHeaders = Object.keys(headers.art_collectibles).sort();
        break;
      }
      default: {
        this.tableHeaders = Object.keys(headers.car).sort();
      }
    }
  }

  filterErrorRows(items: Array<any>): Array<any> {
    let i, j, item;
    const hash = [];
    for (i = 0, j = items.length; i < j; i++) {
      item = items[i];
      if (item.data && item.data.importStatus && typeof item.data.importStatus !== 'undefined' && item.data.importStatus === 'error') {
        hash.push(item);
      }
    }
    return hash;
  }

  filterErrorColumns(items: Array<any>): Array<any> {
    let i, j, item;
    this.tableHeaders = [];
    const hash = [];
    for (i = 0, j = items.length; i < j; i++) {
      item = items[i];
      if (item.data && item.data.importStatus && typeof item.data.importStatus !== 'undefined' && item.data.importStatus === 'error' && item.data.importError && item.data.importError.data && typeof item.data.importError.data.form_errors !== 'undefined') {
        for (const key in item.data.importError.data.form_errors) {
          if (item.data.importError.data.form_errors.hasOwnProperty(key)) {
            const keyTrim = key.trim();
            this.tableHeaders[<any>keyTrim] = item.data.importError.data.form_errors[key];
          }
        }
      }
      hash.push(item);
    }
    this.tableHeaders = this.customHeaders.concat(Object.keys(this.tableHeaders).sort());
    return hash;
  }
}
