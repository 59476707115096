import { ConfigService } from './../../services/config/config.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {
  env = localStorage.getItem('currentEnv') || null;
  envUrl: string;
  constructor(
  public auth: AuthenticationService,
  public router: Router,
  private config: ConfigService,
  public snackBar: MatSnackBar) {
    this.snackBar.open('Checking if you are logged in', 'X', {
      duration: 5000,
    });
  }

  ngOnInit() {
    this.config.environments.valueChanges().subscribe((envs: any) => {
      const envKeys = Object.keys(envs);
      if (envKeys.length === 1) {
        const currentEnv = envKeys[0];
        this.envUrl = envs[currentEnv];
        localStorage.setItem('currentEnv', currentEnv);
        localStorage.setItem('envUrl', this.envUrl);
      }
      this.getToken();
    });
  }

  getToken(): void {
    this.auth.getToken().then(
      token => {
        localStorage.setItem('csrfToken', token);
        this.getCurrentUser();
      },
      () => {
        this.router.navigate(['/login']);
      }
    );
  }

  getCurrentUser(): void {
    this.auth.getCurrentUser().subscribe(
      response => {
        const res = response.json();
        this.checkForConnection(res);
      }
    );
  }

  checkForConnection(response: any): void {
    const token = localStorage.getItem('csrfToken');
    response['token'] = token;
    response['loggedIn'] = true;
    if (response.user.account.uid !== '0') {
      if (this.auth.authenticated) {
        const saveData = this.auth.saveUserInfo(response);
        if (saveData) {
          this.router.navigate(['/home']);
        }
      }
      else {
        this.auth.logout();
        this.router.navigate(['/login']);
      }
    }
    this.returnToLoginPage();

  }

  returnToLoginPage(): void {
    this.router.navigate(['/login']);
    localStorage.removeItem('currentEnv');
  }
}
