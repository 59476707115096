import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';

@Injectable()
export class ConfigService {
  environments: AngularFireObject<any[]>;
  languages: AngularFireObject<any[]>;
  constructor(private af: AngularFireDatabase) {
    this.environments = this.af.object('Config/environments');
    this.languages = this.af.object('Config/languages');
  }
}
