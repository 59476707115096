import { Component } from '@angular/core';
import { UserPrivilegeService } from '../../services/userPrivilege/user-privilege.service';
import { normalUser } from '../../models/userPrivilege.config';
import { MatSnackBar } from '@angular/material';
import * as config from '../../config/requestStatus.config';

export enum tabs {
  ImportValidate = 0,
  FileUpload = 1,
  ImportHistory = 2
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ UserPrivilegeService ]
})
export class HomeComponent {
  downloadUrl: string;
  copyUrl: string;
  tabIndex: number = tabs.ImportValidate;
  onGoingImportData: Object;
  showProgressBar = false;
  constructor(private ups: UserPrivilegeService, public snackBar: MatSnackBar) {
  }

  getUserPrivilege(): boolean {
    return this.ups.userPrivilege(normalUser);
  }

  passCopyLinkToImportValidate(url: string): void {
    this.copyUrl = url;
    this.snackBar.open('URL Copied!', 'X', {
      duration: 1000,
    });
    this.setTabAsImportValidate();
  }

  setTabAsImportValidate(): void {
    this.setTab(tabs.ImportValidate);
  }

  setTabAsImportHistory(): void {
    this.setTab(tabs.ImportHistory);
  }

  setTab(tabIndex: number): void {
    this.tabIndex = tabIndex;
  }

  showImportValidateStatusMsg(msg: string): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
    });
  }

  setOnGoingResponseInImportHistory(importData: Object): void {
    this.onGoingImportData = importData;
  }

  showHideProgressBar(status: any): void {
    if (status === config.RequestStatuses.Requested) {
      this.setProgressBarVisibility();
    }
    else {
      this.unsetProgressBarVisibility();
    }
  }

  setProgressBarVisibility(): void {
    this.showProgressBar = true;
  }

  unsetProgressBarVisibility(): void {
    this.showProgressBar = false;
  }
}
