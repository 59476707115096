import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})

export class LogoutComponent implements OnInit {

  logoutButton = false;
  errorMessage: any;
  constructor(public router: Router, public auth: AuthenticationService) {
    router.events.subscribe(() => {
      this.logoutButton = this.checkUserIsLoggedIn();
    });
  }

  ngOnInit() {
  }

  logout(): void {
    this.auth.logout();
    this.logoutSuccess();
  }

  logoutSuccess(): void {
    this.router.navigate(['/login']);
  }

  logoutError(errorMessage: string): void {
    this.errorMessage = errorMessage;
  }

  checkUserIsLoggedIn(): boolean {
    return this.auth.showLogoutButton();
  }
}
